// src/data/projectsData.tsx
export const projectsData = [
  {
    name: "Gatwick Station Project",
    location: "Gatwick Airport, Gatwick",
    postcode: "RH6 0RD",
    client: "Lindner Prater",
    dateStarted: "2022",
    dateEnded: "2023",
    imageFolder: "GatwickStationProject",
    description: "We actively engaged in executing essential site engineering surveying tasks for Lindner Prater Ltd. Our primary objective was to ensure the seamless installation of critical elements, including roofing, internal/external cladding, and escalator glazing and platform soffits, situated directly above the bustling railway tracks. This project has posed a significant challenge in terms of precision. Our team has conducted survey works to highlight and identify any potential tolerance issues. Additionally, we have conducted thorough surveys of interfaces to proactively highlight and navigate potential challenges. We had to adapt to the dynamic demands of the site, with our team of engineering surveyors working tirelessly days and nights over the past year to meet the tight schedule of the project.",
    linkedIn: "https://www.linkedin.com/feed/update/urn:li:activity:7130330754651152385/",
  },
  {
    name: "53 Clarendon Road",
    location: "Watford, London",
    postcode: "WD17 1LA",
    client: "Regal London",
    dateStarted: "2022",
    dateEnded: "2024",
    imageFolder: "53ClarendonRoad",
    description: "Regal's The Clarendon project in Watford, a distinguished high-rise residential development, was successfully supported by PF Geomatics over the past year. Our dedicated team of engineering surveyors collaborated closely with the Regal team, playing a pivotal role in overseeing the concrete subcontractor's work. The Clarendon, featuring 168 private residences and amenities such as a 25th-floor sky garden, concierge services, a cinema, and a gymnasium, stands as a cornerstone of Watford’s regeneration efforts. Our responsibilities encompassed conducting rigorous quality checks and rebar inspections, as well as carrying out pre-pour and post-pour surveys crucial for maintaining the tight tolerances required for the cladding works. These surveys ensured that the structure’s slab edges and facade brackets met the precise specifications necessary for successful cladding installation. By overseeing general surveying and setting out verifications, our team ensured that all aspects of the construction adhered to the highest standards, contributing significantly to constructing The Clarendon to the required quality and specifications. This project highlights PF Geomatics` commitment to excellence and our ability to meet complex challenges in urban development.",
    linkedIn: "https://www.linkedin.com/feed/update/urn:li:activity:7188247696292368384/",
  },
  {
    name: "Google Headquarters",
    location: "Kings Cross, London",
    postcode: "N1C 4AG",
    client: "Severfield UK",
    dateStarted: "2019",
    dateEnded: "2023",
    imageFolder: "GoogleHeadquarters",
    description: "We played a pivotal role in the construction of the new £1 billion Google Headquarters in Kings Cross, London. Serving as the lead engineers for the steel frame contractor, our managing director led the project for multiple years during the main construction phase. Supported by a dedicated team of engineers, we oversaw every aspect of the steel frame installation, ensuring precise positioning and alignment of every structural element. As the project progressed, our involvement extended beyond the steel framework to include positioning over 30 precast concrete architectural stairs, seamlessly integrating them into the expansive roof garden and walkways surrounding the building's envelope. Our meticulous attention to detail and commitment to excellence contributed to the successful completion of this iconic project, cementing our reputation as industry leaders in engineering innovation and execution.",
    linkedIn: "https://www.linkedin.com/feed/update/urn:li:activity:6674751223184277504/",
  },
  {
    name: "HS2",
    location: "Copthall North Tunnel, West Ruislip",
    postcode: "NW10 6NF",
    client: "Skansa, Costain, Strabag",
    dateStarted: "2022",
    dateEnded: "2024",
    imageFolder: "HS2",
    description: "One of our engineers has been working as a Senior Engineering Surveyor on the HS2 Copthall North Tunnel. This work includes monitoring the excavations of the cut and fill tunnel, monitoring the tunnel structure after construction and during backfill, carrying out pre and post-pour RC concrete surveys and setting up and transferring control throughout the site. The monitoring works involve use of robotic total stations, laser scanning machines and piezometers in a three-pronged attack to ensure structural soundness and safety of the tunnel.",
    linkedIn: "",
  },
  {
    name: "Tempo Project",
    location: "Maidenhead",
    postcode: "SL6 1EH",
    client: "M J Robinson Structures Ltd and ISG",
    dateStarted: "2022",
    dateEnded: "2023",
    imageFolder: "TempoProject",
    description: "Our team provided essential engineering surveys and precision alignment services for structural steelworks, significantly enhancing the project's integrity and success. Working closely with MJ Robinson, we conducted daily detailed surveys, set outs, and established benchmarks, integral to the project's accuracy and structural soundness. Our expertise not only supported MJ Robinson in achieving their construction goals but also contributed to transforming Maidenhead's skyline. This project showcased our commitment to delivering top-tier support and solutions to our clients, reinforcing our position as a leader in construction innovation and collaboration.",
    linkedIn: "https://www.linkedin.com/feed/update/urn:li:activity:7115440233193558016/",
  },
  {
    name: "London Cable Car",
    location: "Greenwich, London",
    postcode: "SE10 0FR",
    client: "Severfield UK",
    dateStarted: "2011",
    dateEnded: "2012",
    imageFolder: "CableCar",
    description: "We were proud to work on the London Cable Car, also known as the Emirates Air Line, ensuring its smooth operation across the Thames. Hired by Severfield UK, our role was to accurately position and continuously monitor the spiral steel pylons that support the cable car system. Working under tight deadlines to meet the opening date ahead of the Olympic Games, we focused on precision and efficiency. Despite the challenging timeline, our team ensured that each pylon was correctly positioned and monitored. Our efforts contributed to the successful launch of the London Cable Car, now a key part of London’s transport system and a popular attraction for both residents and visitors.",
    linkedIn: "",
  },
  {
    name: "The Leadenhall Building",
    location: "112 Leadenhall Street, London",
    postcode: "EC3A 4AF",
    client: "Severfield UK",
    dateStarted: "2011",
    dateEnded: "2013",
    imageFolder: "Leadenhall",
    description: "",
    linkedIn: "",
  },
  {
    name: "Piccadilly Lights",
    location: "Piccadilly, London",
    postcode: "W1D 7ET",
    client: "Severfield UK",
    dateStarted: "2021",
    dateEnded: "2022",
    imageFolder: "PiccadillyLights",
    description: "Working along the steel frame contractor Severfield, our engineers located components for the steel frame for this flagship project overlooking Piccadilly Circus, including complex roof cassettes that enveloped the building in an innovative design. Many of the cassettes cascaded down the sides of the building, becoming features of multiple floors below. The connection plates between these cassettes and the structure were angled and sloped in many different directions to fit the complex shapes together, requiring three dimensional surveys to be done and 3D drawings to be produced showing their positions relative to design in all planes. Some party walls on the perimeter of the building were listed brickwork and required very minimal modifications to accommodate large steel beams and columns being connected into them, this took precision surveying before and after connection to ensure the existing and new structures levels or integrity were not affected. With many obstacles and re-designs on this project it was critical that onsite information was accurate and relayed as quickly as possible to design teams to avoid delays and additional costs to the project.",
    linkedIn: "",
  },
  {
    name: "4-8 Victoria Street",
    location: "Victoria, London",
    postcode: "SW1H 0ET",
    client: "LA Metalworks",
    dateStarted: "2021",
    dateEnded: "2022",
    imageFolder: "4-8VictoriaStreet",
    description: "This flagship project directly opposite Westminster Abbey consisted a renovation of an 8 storey RC frame building with two additional steel frame floors added to the roof. The original open atrium in the centre of the building was filled in with steel beams and columns on every level to increase floor area and usable office space for future tenants. The building style was listed and surrounded on either side with Victoria St and Tothill St, making it a complex and high security job with a sensitive public boundary. Our engineers set up primary and secondary control for the project, created the grid system to datums best fit the original structure, carry out high detail surveys of all existing components on every floor for the designers and architects to work to, set-out position and level of new steel members and building strengthening works throughout the project. We worked closely with the site team to detect design clashes before elements were installed and were continuously relaying new information to the designers and client as the demolition of obsolete existing structure was being done.",
    linkedIn: "",
  },
  {
    name: "Castle Lane",
    location: "Victoria, London",
    postcode: "SW1E 6PR",
    client: "LA Metalworks",
    dateStarted: "2021",
    dateEnded: "2022",
    imageFolder: "CastleLane",
    description: "This large renovation project of 5 buildings a short walk from Victoria tube station required detailed surveys of all existing structural and non-structural brickwork and RC walls. The buildings were originally residential with lots of small rooms with between 5 and 7 levels in each. This meant the surveys required lots of transferred control and instrument setups to complete to a high standard and many months of continuous surveying to get the information to the design teams in good time. Each building had one to two steel frame levels added to the roof so steel components and strengthening works needed to be set out to a high degree of precision to fit the existing structure. Our engineers worked tirelessly alongside the main contractor to collect data and release detailed drawings to ensure designers and other contractors had the information required to keep working and avoid any costly delays.",
    linkedIn: "",
  },
  {
    name: "105 Victoria Street",
    location: "Victoria, London",
    postcode: "SW1E 6RA",
    client: "Severfield",
    dateStarted: "2024",
    dateEnded: "2024",
    imageFolder: "105VictoriaStreet",
    description: "On the 105 Victoria Street project, we surveyed holding down bolts and levelled packers to assist Severfield in landing their columns. Additionally, we plumbed the columns and provided an as-built report, ensuring precise installation and alignment.",
    linkedIn: "",
  },
  {
    name: "40 Eastbourne Terrace",
    location: "Paddington, London",
    postcode: "W2 6LG",
    client: "Harrogate Steel",
    dateStarted: "2022",
    dateEnded: "2023",
    imageFolder: "40EastbourneTerrace",
    description: "This project right next to the Elizabeth line entrance at Paddington Station comprised the strengthening of an existing 5 level RC building and adding two steel frame levels to the top. Our engineers had to survey every RC component of the existing structure and relay this information to the site and design teams. This building being over 70m long and each floor containing hundreds of columns made it a challenging task requiring lots of internal and external control points and setups to achieve. Our engineers also set-out the steel frame on top by marking the precise locations of the existing RC columns from the floor below to ensure the new load was transferred most effectively through the existing structure.",
    linkedIn: "",
  },
  {
    name: "41 Tower Hill",
    location: "Tower Hill, London",
    postcode: "EC3N 4DX",
    client: "CR Construction",
    dateStarted: "2024",
    dateEnded: "2024",
    imageFolder: "41TowerHill",
    description: "This refurbishment project at 41 Tower Hill for our client CR Construction involved comprehensive control checks and detailed surveying of existing levels. Our engineers meticulously carried out these tasks to ensure precision and accuracy throughout the project. We were also responsible for setting out gridlines and datums for the blockwork team, ensuring that every aspect of their work was aligned perfectly with the project's requirements. Additionally, our team marked out structural openings for the demolition team, facilitating a smooth and efficient process.",
    linkedIn: "",
  },
  {
    name: "Warwick House Street",
    location: "St James, London",
    postcode: "SW1Y 5AT",
    client: "Alpine Group Ltd",
    dateStarted: "2024",
    dateEnded: "2024",
    imageFolder: "WarwickHouseStreet",
    description: "We collaborated with Alpine Group, a steel fabricator subcontractor, on an office refurbishment project. Our team conducted an accurate survey of the existing structure, including the slab, beams, columns, and bathroom pods. We provided an As-Built survey in the form of an AutoCAD drawing, which designers used to size their components to fit the existing structure seamlessly.",
    linkedIn: "",
  },
  {
    name: "Guildford MSCP",
    location: "Guildford Train Station, Guildford",
    postcode: "GU1 4UT",
    client: "Capital Steel Strutures Ltd",
    dateStarted: "2024",
    dateEnded: "2024",
    imageFolder: "GuildfordMSCP",
    description: "On behalf of Capital Steel Structures, we conducted comprehensive surveys of the columns to ensure they were level, correctly positioned, and plumb. Following these checks, we provided an As-Built survey for the main contractor's approval. This critical step ensures that everything meets the required standards before the grouting of the baseplates, facilitating a smooth handover.",
    linkedIn: "",
  },
  {
    name: "Piccadilly Pavillion",
    location: "Piccadilly Circus, London",
    postcode: "W1J 0DA",
    client: "Criterion Capital / Piccadilly Construction",
    dateStarted: "2024",
    dateEnded: "2024",
    imageFolder: "PiccadillyPavillion",
    description: "On the Piccadilly Pavillion project, we have set up site control, set out gridlines, set out wall lines, and screed stop ends. This work ensured the project was executed with precise alignment and accuracy, facilitating smooth progress.",
    linkedIn: "",
  },
  {
    name: "Haymarket House",
    location: "Piccadilly Circus, London",
    postcode: "SW1Y 4RX",
    client: "Criterion Capital / Piccadilly Construction",
    dateStarted: "2024",
    dateEnded: "2024",
    imageFolder: "HaymarketHouse",
    description: "For the Haymarket House project, we established site control, surveyed existing slab levels, and provided as-built drawings.",
    linkedIn: "",
  },
];
